/* tslint:disable */
/* eslint-disable */
/**
 * CommuteTime
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScopeRequiredResponse
 */
export interface ScopeRequiredResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ScopeRequiredResponse
     */
    auth?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScopeRequiredResponse
     */
    admin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScopeRequiredResponse
     */
    user?: boolean;
}

export function ScopeRequiredResponseFromJSON(json: any): ScopeRequiredResponse {
    return ScopeRequiredResponseFromJSONTyped(json, false);
}

export function ScopeRequiredResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScopeRequiredResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auth': !exists(json, 'auth') ? undefined : json['auth'],
        'admin': !exists(json, 'admin') ? undefined : json['admin'],
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function ScopeRequiredResponseToJSON(value?: ScopeRequiredResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auth': value.auth,
        'admin': value.admin,
        'user': value.user,
    };
}


